import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { arrowRight, Icon } from '../components/icon';

interface Piece {
    id: string;
    frontmatter: {
        path: string;
        title: string;
        subtitle: string;
        images: any[];
    };
}

function renderArticle(piece: Piece) {
    const { frontmatter } = piece;
    return (
        <article key={piece.id}>
            {frontmatter.images && (
                <Link to={frontmatter.path} className="image filtered">
                    <img
                        src={frontmatter.images[0].childImageSharp.fixed.src}
                        alt={frontmatter.title}
                    />
                </Link>
            )}
            <p>
                <strong>{frontmatter.title}</strong> {frontmatter.subtitle}
            </p>
            <ul className="actions">
                <li>
                    <Link to={frontmatter.path} className="button small next">
                        Details
                        <Icon
                            icon={arrowRight}
                            style={{ marginLeft: '0.8rem', color: '#ddd' }}
                        />
                    </Link>
                </li>
            </ul>
        </article>
    );
}

function renderBanner(piece: Piece) {
    const { frontmatter } = piece;
    return (
        <section className="banner">
            <div className="image" data-position="right">
                <img
                    src={frontmatter.images[0].publicURL}
                    alt={frontmatter.title}
                />
            </div>
            <div className="content">
                <h1>{frontmatter.title}</h1>
                <ul className="actions">
                    <li>
                        <Link to={frontmatter.path} className="button next">
                            See More
                            <Icon
                                icon={arrowRight}
                                style={{ marginLeft: '0.8rem', color: '#ddd' }}
                            />
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    );
}

function IndexPage({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const nodes = data.allMarkdownRemark.edges.map(x => x.node);
    return (
        <Layout banner={renderBanner(nodes[0])}>
            <SEO title="Home" />
            <div className="inner">
                <div className="posts">{nodes.map(renderArticle)}</div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        etsy
                        title
                        subtitle
                        images {
                            id
                            name
                            relativePath
                            modifiedTime
                            publicURL
                            childImageSharp {
                                fixed(width: 300) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
